import { apiClient } from '../index';

/**
 * [login description]
 * @param  {[type]} email    [description]
 * @param  {[type]} password [description]
 * @return {[type]}          [description]
 */
function login(email, password) {
  return apiClient.post('/auth/login', { email, password });
}

/**
 * [user description]
 * @return {[type]}          [description]
 */
function user() {
  return apiClient.get('/users/perfil');
}

/**
 * [user description]
 * @return {[type]}          [description]
 */
function getUsers() {
  return apiClient.get('/users/all');
}

export default { login, user, getUsers };
