import Vuetify from '@/plugins/vuetify';

export default {
  namespaced: true,

  state: {},

  mutations: {
    LOGIN_BACKGROUND() {
      if ( Vuetify.framework.theme.themes.light ) {
        Vuetify.framework.theme.themes.light.background = Vuetify.framework.theme.themes.light.backgroundLogin;
      }
    },
    DASHBOARD_BACKGROUND() {
      if ( Vuetify.framework.theme.themes.light ) {
        Vuetify.framework.theme.themes.light.background = Vuetify.framework.theme.themes.light.backgroundDashboard;
      }
    },
  },

  actions: {
    loginBackground({ commit }) {
      commit('LOGIN_BACKGROUND');
    },
    dashboardBackground({ commit }) {
      commit('DASHBOARD_BACKGROUND');
    },
  },
};
