import axios from 'axios';
import Store from '../store/index';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { 'Content-Type': 'application/json' },
});

apiClient.interceptors.request.use(function(config) {
  // Do something before request is sent
  const token = localStorage.getItem('token-YPm0ZesF2yAuZvGn');
  config.headers['Authorization'] = 'Bearer ' + token;
  return config;
});

// Expiration token manager
apiClient.interceptors.response.use((response) => response,
    (error) => {
      if (error.response.status === 401) {
        Store.dispatch('session/logout', true);
      }
      return Promise.reject(error);
    },
);

export { apiClient };
