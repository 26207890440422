import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import VuetifyConfirm from './components/Confirm';

// import VueJsonToTable from 'vue-json-to-table';
import VueToastify from 'vue-toastify';

Vue.use(VueToastify);
// Vue.use(VueJsonToTable);
Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'Aceptar',
  buttonFalseText: 'Cancelar',
  buttonTrueColor: 'primary',
  buttonFalseColor: 'grey',
  buttonTrueFlat: false,
  buttonFalseFlat: true,
  color: 'warning',
  // icon: 'warning',
  title: 'Advertencia',
  width: 350,
  property: '$confirm',
});
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
