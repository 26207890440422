import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '@/plugins/i18n';
import Root from '@/views/Root.vue';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: { name: 'dashboard', params: { locale: i18n.locale } },
  },
  {
    path: '/:locale',
    component: Root,
    beforeEnter: (to, from, next) => {
      const { locale } = to.params;
      if (!Object.keys(i18n.messages).includes(locale)) next(i18n.locale);
      else next();
    },
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: () => import('@/views/Dashboard.vue'),
        redirect: { name: 'search', params: { locale: i18n.locale } },
        children: [
          {
            path: 'search',
            name: 'search',
            component: () => import('@/views/SearchEconomic.vue'),
            meta: { authorize: ['superadmin', 'facilitador', 'inase'] },
          },
          {
            path: 'importxlsx',
            name: 'importxlsx',
            component: () => import('@/views/ImportXlsx.vue'),
            meta: { authorize: ['superadmin'] },
          },
          {
            path: 'report',
            name: 'report',
            component: () => import('@/views/Reports.vue'),
            meta: { authorize: ['superadmin', 'facilitador'] },
          },
          {
            path: 'reports',
            name: 'reports',
            component: () => import('@/views/Reports.vue'),
            meta: { authorize: ['superadmin', 'facilitador'] },
          },
          {
            path: 'employees',
            name: 'employees',
            component: () => import('@/views/Employees.vue'),
            meta: { authorize: ['superadmin', 'facilitador', 'inase'] },
          },
          {
            path: 'settings',
            name: 'settings',
            component: () => import('@/views/Settings.vue'),
            meta: { authorize: ['superadmin', 'facilitador'] },
          },
        ],
      },
      {
        path: 'login',
        component: Root,
        children: [
          {
            path: '/',
            name: 'login',
            component: () => import('@/views/Login.vue'),
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async(to, from, next) => {
  const publicPages = ['login'];
  const loggedIn = localStorage.getItem('token-YPm0ZesF2yAuZvGn') && localStorage.getItem('user');
  const { authorize } = to.meta;
  try {
    const authRequired = !(publicPages.includes(to.name));
    if (authRequired && !loggedIn) {
      next({ name: 'login', params: { locale: i18n.locale } });
    } else {
      to.params.locale = i18n.locale;
      if (authorize) {
        await store.dispatch('session/user');
        const currentUser = store.state.session.user;
        // console.log('currentUser: ', currentUser);
        if (!currentUser.role) {
          // not logged in so redirect to login page with the return url
          return next({ name: 'login', query: { returnUrl: to.path } });
        }
        // check if route is restricted by role
        if (authorize.length && !authorize.includes(currentUser.role)) {
          // role not authorised so redirect to home page
          return next({ name: 'search', params: { locale: i18n.locale } });
        }
      }
      next();
    }
  } catch (error) {
    console.log('error------', error);
    next({ name: 'login', params: { locale: i18n.locale } });
  }
});
export default router;
