import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
//import 'vuetify/dist/vuetify.min.css';
import colors from './colors';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import '@/scss/main.scss';

Vue.component('font-awesome-icon', FontAwesomeIcon); // Register component globally
library.add(fas); // Include needed icons
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        backgroundLogin: '#30375b',
        backgroundDashboard: '#fff',
        background: '#30375b',
        primary: '#30375b',
        secondary: '#222d32',
        pending: '#E9BD77',
        reviewed: '#b0bb60',
        passed: '#88C363',
        rejected: '#E88476',
        canceled: '#9D5950',
        solucionado: '#60bbb9',
        grey: colors.grey.darken2,

      },
    },
  },
  icons: { iconfont: 'fa' || 'faSvg' },
});
