import authService from '../../services/modules/auth';

export default {
  namespaced: true,

  state: { expired: false, user: {} },

  mutations: {
    LOGOUT(state, expired) {
      localStorage.removeItem('token-YPm0ZesF2yAuZvGn');
      localStorage.removeItem('user');
      if (expired) {
        state.expired = true;
        setTimeout(() => {
          location.reload();
        }, 3000);
      } else {
        location.reload();
      }
    },
    ADD_USER(state, user) {
      state.user = { ...user };
    },
  },

  actions: {
    logout({ commit }, expired = false) {
      commit('LOGOUT', expired);
    },
    async user({ commit }) {
      const currentUser = await authService.user();

      commit('ADD_USER', currentUser.data.user);
    },
  },
};
