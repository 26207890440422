export default {
  commonForm: {
    email: 'Correo electrónico',
    password: 'Contraseña',
    phone: 'Teléfono',
    discount: 'Descuento',
    rfc: 'RFC',
    ivaPercentage: 'Porcentaje IVA',
    iva: 'IVA',
    date: 'Fecha',
    add: 'Agregar',
    cancel: 'Cancelar',
    ok: 'Ok',
    confirm: 'Confirmar',
    save: 'Guardar',
    edit: 'Editar',
    selectNoData: 'Sin datos',
    address: {
      street: 'Calle',
      extNumber: 'Número Exterior',
      intNumber: 'Número Interior',
      suburb: 'Colonia',
      postalCode: 'Código Postal',
      settlement: 'Localidad',
      town: 'Municipio',
      state: 'Estado',
      country: 'País',
    },
  },
  commonWords: {
    saved: 'Guardado',
    edited: 'Editado',
    error: 'Error',
    tryLaterSave: 'Ocurrió un error al guardar elemento. Por favor intente más tarde',
    tryLater: 'Ocurrió un error. Por favor intente más tarde',
    errorData: 'Datos improcesables',
    errorDataText: 'Por favor asegúrese que los datos sean correctos',
  },
  commonDateSearch: { inputLabel: 'Buscar por periodo' },
  commonTimeSearch: { inputLabel: 'Periodo de tiempo' },
  commonTable: {
    perPageText: 'Registros por página',
    noDataText: 'No hay registros',
    dateTime: 'Fecha/Hora',
  },
  login: {
    login: 'Ingresar',
    warningEmailPass: 'Usuario o contraseña inválidos',
  },
  session: {
    expired: 'Su sesión ha expirado',
    redirect: 'Redireccionando',
    memberSince: 'Miembro desde',
  },
  validate: {
    emailRequired: 'Correo electrónico requerido',
    emailNotValid: 'Correo electrónico inválido',
    passwordRequired: 'Contraseña requerida',
    numbersOnly: 'Solo caracteres numéricos',
    phoneLength: 'Mínimo 10 caracteres',
    rfcLength: 'Mínimo 12 caracteres',
    postalCodeLength: '5 caracteres',
    discountValidation: 'Descuento debe ser menor a valor unitario',
    urlNotValid: 'URL inválido',
  },
  appBar: {
    search: 'Busqueda',
    import: 'Importación',
    reports: 'Reporte',
    employees: 'Vendedores',
    logout: 'Cerrar sesión',
    settings: 'Configuración',
  },
  billing: {
    steps: {
      benefactorData: 'Datos del Emisor',
      recipientData: 'Datos del Receptor',
      addConcept: 'Agregar Conceptos',
      emit: 'Facturar',
    },
    benefactorData: {
      benefactor: 'Elaboró',
      address: 'Dirección Matriz',
      expeditionPlace: 'Lugar de Expedición',
      expeditionMainPlace: 'Lugar de Expedición Matriz',
      savedSuccess: 'Emisor guardado correctamente',
    },
    recipientData: {
      localClient: 'Cliente',
      comportiaClient: 'Cliente de Comportia',
      newData: 'Ingresar datos',
      name: 'Nombre',
      cfdi: 'Uso CFDI',
      savedSuccess: 'Receptor guardado correctamente',
    },
    addConcept: {
      conceptSubtitle: 'Conceptos',
      taxSubtitle: 'Impuestos',
      dialogTitle: 'Agregar Concepto',
      delete: 'Eliminar',
      details: 'Detalles',
      amount: 'Importe',
      deleteTitle: '¿Está seguro que desea eliminar el concepto?',
      conceptTable: {
        productKey: 'Clave Producto',
        quantity: 'Cantidad',
        unitValue: 'Valor Unitario',
      },
      taxesTable: {
        taxType: 'Tipo Impuesto',
        base: 'Base',
        tax: 'Impuesto',
        rate: 'Tasa o Cuota',
        factorType: 'Tipo Factor',
      },
      dialogForm: {
        quantity: 'Cantidad',
        productKey: 'Clave del producto',
        keyCaption: 'Leyenda clave',
        unitKey: 'Clave de unidad',
        description: 'Descripción',
        unitValue: 'Valor unitario',
        taxBase: 'Base del impuesto',
        idNumber: 'No de identificación',
        unit: 'Unidad',
        unitCaption: 'Leyenda de Unidad',
        amountDiscount: 'Importe - Descuento',
      },
    },
    emit: {
      emitBill: 'Emitir Factura',
      taxRegime: 'Régimen Fiscal',
      taxRegimeCaption: 'Leyenda Régimen Fiscal',
      voucherType: 'Tipo de comprobante',
      documentType: 'Tipo de Documento',
      serie: 'Serie',
      payment: 'Forma de Pago',
      paymentConditions: 'Condiciones de pago',
      paymentMethod: 'Método de pago',
      subTotal: 'Subtotal',
      total: 'Total',
      totalWithheldTaxes: 'Total de impuestos retenidos',
      totalTransferredTaxes: 'Total de impuestos transladados',
      currency: 'Moneda',
      exchangeRate: 'Tipo de cambio',
      totalIvaPercentage: 'Total porcentaje de IVA',
      confirmEmitTitle: '¿Seguro que desea emitir la factura?',
      emitSuccessTitle: 'Emitido',
      emitSuccessText: 'La factura se ha emitido exitosamente',
    },
  },
  receipts: {
    title: 'Facturas',
    tooltipPdf: 'Descargar PDF',
    tooltipXml: 'Descargar XML',
    tooltipCancel: 'Cancelar comprobante',
    tooltipSend: 'Enviar Comprobante',
    table: {
      client: 'Cliente',
      invoice: 'Folio',
      total: 'Total',
      canceled: 'Cancelado',
    },
    cancelTitle: '¿Está seguro que desea cancelar el comprobante?',
    cancelSuccessTitle: 'Cancelado',
    cancelSuccessText: 'El comprobante se ha cancelado exitosamente',
    sendTitle: '¿Está seguro que desea enviar el comprobante?',
    sendSuccessTitle: 'Enviado',
    sendSuccessText: 'El comprobante se ha enviado exitosamete',
  },
  record: {
    title: 'Bitácora',
    table: {
      code: 'Código',
      message: 'Mensaje',
      folio: 'Folio',
    },
  },
  projects: {
    title: 'Proyectos',
    addDialogTitle: 'Agregar Proyecto',
    editDialogTitle: 'Editar Proyecto',
    tokenDialogTitle: 'Proyecto',
    addProjectForm: {
      name: 'Nombre',
      url: 'URL',
    },
    tokenForm: { token: 'Token' },
    updateToken: 'Renovar token',
    updateTokenTitle: '¿Está seguro que desea renovar el token?',
    updateTokenText: 'El token actual dejará de ser válido',
    updateTokenSuccessTitle: 'Renovado',
    updateTokenSuccess: 'Token renovado correctamente',
    savedSuccess: 'Proyecto guardado correctamente',
    editSuccess: 'Proyecto editado correctamente',
  },
};
