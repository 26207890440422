<template>
  <router-view />
</template>

<script>
export default {
  name: 'Root',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const { locale } = to.params;
      if (Object.keys(vm.$i18n.messages).includes(locale)) vm.$i18n.locale = locale;
    });
  },
};
</script>
