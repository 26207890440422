<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: 'App',
  computed: {
    theme: function() {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light';
    },
  },
};
</script>
